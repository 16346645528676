import React from 'react';
import { string } from 'prop-types';
import loadable from '@loadable/component';

import useStyles from './styles';

const Grid = loadable(() => import('@material-ui/core/Grid'));
const Typography = loadable(() => import('@material-ui/core/Typography'));
const Paper = loadable(() => import('@material-ui/core/Paper'));

const LazyLandingSignupIconList = loadable(() => import('components/icon-list/landing-signup'));
const LazyRegisterActionCta = loadable(() => import('components/cta/register-action'));

const LandingSignUpSection = ({ hash = '' }) => {
  const styles = useStyles();

  return (
          <Grid spacing={1}
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              className={styles.fourTopBotMargin}
           >
            <Grid item xs={12} md={10} lg={10}>
              <Paper className={styles.paper} elevation={4}>
                <Typography
                  variant="h4"
                  component="h3"
                  align="center"
                  className={styles.headerBlue}>
                  Build Better Customer Loyalty
                </Typography>
                <LazyLandingSignupIconList/>
                <div className={styles.twoToFourBottomMargin}>
                  <LazyRegisterActionCta
                    primary="Register with Total Loyalty"
                    primarySmall="Register Now"
                    secondary="Sign up and build better Loyalty"
                    />
                </div>
              </Paper>
          </Grid>
        </Grid>
  );
};

LandingSignUpSection.propTypes = { hash: string };

export default LandingSignUpSection;
